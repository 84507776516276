import {PaymentFrequency} from "./types";
import {VPOSActionsEnum} from "../../services/payment/payment.types";
import {IBadge} from "../../components/general/Badge/types";

export const PAYMENT_FREQUENCY_HANDLERS = {
    [PaymentFrequency.ONE_TIME]: VPOSActionsEnum.init,
    [PaymentFrequency.MONTHLY]:  VPOSActionsEnum.bind
}
export const paymentSuggestionsBadges: IBadge[] = [
    {
        id: 0,
        title: '25 USD',
        value: '25',
    },
    {
        id: 1,
        title: '50 USD',
        value: '50',
    },
    {
        id: 2,
        title: '200 USD',
        value: '200',
    },
    {
        id: 3,
        title: '500 USD',
        value: '500',
    },
    {
        id: 4,
        title: '1000 USD',
        value: '1000',
    },
]

export const paymentFrequencyBadges: IBadge[] = [
    {
        id: 0,
        title: 'One-Time',
        value: 'ONE_TIME',
    },
    {
        id: 0,
        title: 'Monthly',
        value: 'MONTHLY',
    }
]
