import { IInput } from './index.types';

import styles from './styles.module.css';

const Input = ({
                   id,
                   labelClassname= '',
                   className = '',
                   wrapperClassName= '',
                   type = 'text',
                   name,
                   value,
                   touched,
                   errorMessage,
                   handleChange,
                   handleBlur,
                   handleKeyPress,
                   disabled,
                   label,
                   maxLength,
                   placeholder = '',
                   checked
               }: IInput) => {

    return (
        <div
            className={`form-input ${errorMessage && touched ? 'has-error' : ''} ${styles.wrapper} ${wrapperClassName}`}>
            {label && <label htmlFor={id} className={`${styles.label} ${labelClassname}`}>{label}</label>}
                <input
                    id={id}
                    type={type}
                    name={name}
                    value={value}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    className={`${errorMessage && touched ? styles.inputError : ''} ${styles.input} ${className}`}
                    disabled={disabled}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    checked={checked}
                />
            {
                errorMessage && touched && <p className={styles.errorMassage}>{errorMessage}</p>
            }
        </div>
    );
};

export default Input
