import React, {useEffect, useState} from 'react';
import styles from "./styles.module.css";
import generalStyles from '../../../shared/pages/styles.module.css';
import { useLocation } from "react-router-dom";
import {parseLocationSearch} from "../../../../helpers/locationSearch";
import successIcon from '../../../../assets/images/successIcon.svg';
import {Button} from "../../../../components/general";
import {Loader} from "../../../../components/display/Loader/Loader";
import {PaymentDetailResponse} from "../../../../services/payment/payment.types";
import {getPaymentDetails} from "../../../../services/payment/payment.service";

const AmeriaBack:React.FC = () => {
    const location = useLocation();
    const queryParams =  parseLocationSearch(location.search);
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetailResponse | undefined>();

    const init = async () => {
        const {paymentID, userId} = queryParams;
        const paymentDetail = await getPaymentDetails({
            userId: userId as number,
            PaymentID: `${paymentID}`,
        });
        if(paymentDetail?.ResponseCode == '00') {
            setPaymentDetails(paymentDetail);
        }
    }

    const navigateToHome = () => {
        window.location.href = `${process.env.REACT_APP_TECH_FOR_ALL_ORIGIN_URL}`;
    }

    useEffect(() => {
        init()
    }, []);

    return (
        <div style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {
                 !paymentDetails ?
                    <Loader /> :
                    <div className={`${generalStyles.wrapper} ${styles.wrapper}`}>
                        <div className={styles.paymentPreviewWrapper}>
                            <div className={styles.success}>
                                <div className={styles.successMessage}>Payment Success!</div>
                                <img src={successIcon} alt="Success..."/>
                                <div className={styles.dashedLine}></div>
                            </div>
                            <div className={styles.paymentDetails}>
                                <div>
                                    <li>Reference Number</li>
                                    <span>{paymentDetails?.CardNumber}</span>
                                </div>
                                <div>
                                    <li>Date</li>
                                    <span>{paymentDetails.DateTime.split(' ')[0]}</span>
                                </div>
                                <div>
                                    <li>Time</li>
                                    <span>{paymentDetails.DateTime.split(' ')[1]}</span>
                                </div>
                                <div style={{ width: '40%', borderBottom: '1px dashed #DCDEE0'}}></div>
                                <div>
                                    <li>Amount</li>
                                    <span>{paymentDetails.Amount}</span>
                                </div>
                            </div>
                            <div className={styles.solidLine}></div>
                            <Button
                                style={{ marginTop: '54px' }}
                                type='submit'
                                styleType='primary'
                                disabled={false}
                                className={styles.receiptBtn}
                                onClick={navigateToHome}
                            >
                                Go Home
                            </Button>
                        </div>
                    </div>
            }
        </div>
    );
};

export default AmeriaBack;
