import styles from './styles.module.css';
import {IBadge} from "./types";
import React from "react";
const Badge = ({ title, selected, onCLick, style={} }: IBadge & { selected: boolean, onCLick: () => void, style?: React.CSSProperties}) => {
    return (
        <div style={{ backgroundColor: selected ?  '#9A5AED' : '#FFFFFF', color:  selected ?  '#FFFFFF' : '#4558FF', ...style }} onClick={onCLick} className={styles.badgeWrapper}>
            {title}
        </div>
    );
};

export { Badge };
