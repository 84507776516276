import {
    InitPaymentRequest, initPaymentResponse,
    PaymentDetailRequest,
    PaymentDetailResponse,
    VPOSActionsEnum
} from "./payment.types";

const { REACT_APP_PAYMENT_SERVICE } = process.env;

export async function initPayment(payload: InitPaymentRequest, errorHandler: (e: any) => void, action:VPOSActionsEnum=VPOSActionsEnum.init) {
    try {
        const res = await fetch(`${REACT_APP_PAYMENT_SERVICE}`, {
            body: JSON.stringify({
                action,
                payload,
            }),
            headers: {
                'Content-type': 'application/json',
            },
            method: 'POST',
        }).then(res => res.json());

        return res.data as initPaymentResponse;
    }  catch (e) {
        console.log('e ::', e);
        errorHandler(e);
    }
}

export async function getPaymentDetails(payload: PaymentDetailRequest) {
    try {
        const res =  await fetch(`${REACT_APP_PAYMENT_SERVICE}`, {
            body: JSON.stringify({
                payload,
                action: VPOSActionsEnum.detail,
            }),
            headers: {
                'Content-type': 'application/json',
            },
            method: 'POST',
        }).then(res => res.json());
        return res.data as PaymentDetailResponse;
    } catch (e) {
        console.log('e ::', e);
    }
}
