export enum VPOSActionsEnum {
    init= "init",
    detail = 'detail',
    bind = 'bind',
}

export interface InitPaymentRequest {
    name: string;
    phone: string;
    email: string;
    Currency?: CURRENCY_TYPES; // todo -> default Currency is AMD
    Opaque?: string;
    CardHolderID?: string;
    Timeout?: number;
    Amount: string;
    Description?: string;
    BackURL?: string;
}

export interface initPaymentResponse {
    PaymentID: string;
    ResponseCode: number;
    ResponseMessage: string;
}

export interface PaymentDetailRequest {
    userId: number;
    PaymentID: string;
}

export interface PaymentDetailResponse {
    Amount: number;
    ApprovedAmount: number;
    ApprovalCode: string;
    CardNumber: string;
    ClientName: string;
    ClientEmail: string;
    Currency: string;
    DateTime: string;
    DepositedAmount: number;
    Description: string;
    MerchantId: string;
    Opaque: string;
    OrderID: string;
    PaymentState: string;
    PaymentType: PAYMENT_TYPE;
    ResponseCode: string;
    rrn: string;
    TerminalId: string;
    TrxnDescription: string
}

export enum CURRENCY_TYPES {
    AMD = 'AMD',
    EUR = 'EUR',
    USD = 'USD',
}

export enum PAYMENT_TYPE {
    MAIN_REST = 5,
    PAY_PAL = 6,
    BINDING = 7
}

export enum LANGUAGES {
    am = 'am',
    ru = 'ru',
    en = 'en',
}
