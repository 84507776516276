export const errorsMessages =  {
    required: 'Please fill in the required field.',
    email: 'Please enter a valid email address.',
    phone: 'Please enter a valid phone number.',
    amount: 'Please select the payment amount or type an amount you want.',
    paymentFrequency: 'Please select the payment frequency, one-time or monthly.',
    localStorageAdd: 'Can\'t save changes in local storage.',
    localStorageLoad: ' Can\'t load state from local storage.',
    localStorageRemove: 'Can\'t remove state from local storage.'
}
