import React from 'react';
import generalStyles from '../../../shared/pages/styles.module.css';
import styles from './styles.module.css';

import InitPaymentForm from '../../forms/payment/payment';

const InitPayment = () => {
    return (
        <div className={`${generalStyles.wrapper} ${styles.wrapper}`}>
            <InitPaymentForm />
        </div>
    );
};

export default InitPayment;
