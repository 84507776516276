import {useLocation, useNavigate} from "react-router-dom";
import React from 'react';
import styles from './styles.module.css'
import generalStyles from '../styles.module.css';
import defaultErrorIcon from '../../../../assets/images/whoops.svg'

const defaultErr  =
    <>
        Our system is feeling a bit rebellious today. <br/>
        But we won't let it get the best of us. Stay tuned for updates.
    </>

export const DefaultErrorBody = ({ errIcon, err }: { errIcon?: string, err?: string}) => {
    const location = useLocation();

    return (
    <>
        <div className={styles.errorIconWrapper}>
            <img src={errIcon || defaultErrorIcon} alt="Error..."/>
        </div>
        <div className={styles.errorMessage}>
            { err || location.state?.message || defaultErr}
        </div>
    </>
    )
}

const ErrorPage = ({ children=<DefaultErrorBody/> }: { children?: JSX.Element }) => {
    const location = useLocation();
    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate(location.state?.from || '/')
    }

    return (
        <div className={`${generalStyles.wrapper} ${styles.wrapper}`}>
            <div className={styles.errorBody}>
                <div className={styles.title}>Whoops!</div>
                {children}
            </div>
        </div>
    );
};

export default ErrorPage;