import { useFormik} from 'formik'
import React, {useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Input } from "../../../../components/general";
import styles from './payment.form.module.css';
import {
    initPaymentInitialValues as initialValues,
    initPaymentSchema as validationSchema
} from '../../schemas/payment';
import {IPaymentForm, PaymentFrequency} from "../../types";
import {ERROR_MASSAGES} from "../../pages/ameriaError/constants";
import {Loader} from "../../../../components/display/Loader/Loader";
import {CURRENCY_TYPES, LANGUAGES} from "../../../../services/payment/payment.types";
import {initPayment} from "../../../../services/payment/payment.service";
import {
    PAYMENT_FREQUENCY_HANDLERS,
    paymentFrequencyBadges,
    paymentSuggestionsBadges
} from "../../constants";
import paymentBanner from '../../../../assets/images/paymentBanner.png';
import {Badge} from "../../../../components/general/Badge/Badge";
import {CustomAmountInput} from "../../components/CustomAmountBadge/CustomAmountInput";
import {writeToSpreadsheet} from "../../../../services/googleSpreadsheet/writeToSpreadsheet";

const { REACT_APP_TRANSACTION_SPREADSHEET_ID } = process.env;

const InitPaymentForm: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const [customAmount, setCustomAMount] = useState<string>('');

    const errorHandler = (error: string) => {
        navigate('/ameria-error', { state: { from: location.pathname, message: ERROR_MASSAGES.TRY_LATER  }})
    }

    const onSubmit = async (data: IPaymentForm) => {
            const { paymentFrequency , ...payloadRest} = data;
            const payload = {
                ...payloadRest,
                Currency: CURRENCY_TYPES.USD,
            }

        const response = await initPayment(payload, errorHandler, PAYMENT_FREQUENCY_HANDLERS[paymentFrequency as keyof typeof PaymentFrequency]);

            if(response?.ResponseCode && response?.PaymentID) {
                await writeToSpreadsheet(`${REACT_APP_TRANSACTION_SPREADSHEET_ID}`, [
                    new Date(),
                    payload.name,
                    payload.phone,
                    payload.email,
                    payload.Amount,
                    CURRENCY_TYPES.USD,
                    payload.Description,
                ])
                window.location.href = `${process.env.REACT_APP_VPOS_PAYMENT_REDIRECT_URL}?id=${response.PaymentID}&lang=${LANGUAGES.en}`
            }
    };

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        dirty,
        isValid,
        isSubmitting,
        setFieldValue
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    });

    const handleChangeCustomAmount = (amount: string) => {
        if(amount  && !Number(amount)) {
            return;
        }
        setCustomAMount(amount);
        setFieldValue('Amount', amount);
    };

    const handleAmountBadgeClick = (amount: string) => {
        setCustomAMount('');
        setFieldValue('Amount', amount);
    }

    const handlePaymentFrequencyBadgeClick = (frequency: PaymentFrequency) => {
        setFieldValue('paymentFrequency', frequency);
    }

    const showPaymentBadgeHelpMessages = (formValues: IPaymentForm) => {
     return (formValues.name && formValues.phone && formValues.email) && (!formValues.Amount || !formValues.paymentFrequency)
    }

    return (
        <>
            {
                isSubmitting ? <Loader/> :
                    <div className={styles.paymentFormWrapper}>
                        <div className={styles.paymentBannerWrapper}>
                            <img className={styles.paymentBanner} src={paymentBanner} alt="banner"/>
                        </div>
                        <div className={styles.formWrapper}  style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <form className={styles.formBody} onSubmit={handleSubmit}>
                                <div className={styles.formSections}>
                                    <div className={styles.sectionPersonalInfo}>
                                        <Input
                                            type='text'
                                            label='Name Surname'
                                            name='name'
                                            id='name'
                                            touched={touched.name}
                                            value={values.name}
                                            placeholder='Please enter your name'
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            errorMessage={errors.name}
                                            className={styles.formInput}
                                        />
                                        <Input
                                            type='text'
                                            label='Phone Number'
                                            name='phone'
                                            id='phone'
                                            touched={touched.phone}
                                            value={values.phone}
                                            placeholder='Please enter your phone number'
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            errorMessage={errors.phone}
                                            className={styles.formInput}
                                        />
                                        <Input
                                            type='text'
                                            label='Email Address'
                                            name='email'
                                            id='email'
                                            touched={touched.email}
                                            value={values.email}
                                            placeholder='Please enter your email address'
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            errorMessage={errors.email}
                                            className={styles.formInput}
                                        />
                                    </div>
                                    <div className={styles.badgesWrapper}>
                                        {
                                            paymentSuggestionsBadges.map(({id, value, ...rest }) =>
                                                <Badge
                                                    id={id}
                                                    key={id}
                                                    value={value}
                                                    selected={value === values.Amount}
                                                    onCLick={() => handleAmountBadgeClick(value)}
                                                    { ...rest}
                                                />
                                            )
                                        }
                                        <CustomAmountInput value={customAmount} onChange={(value) => handleChangeCustomAmount(value)}/>
                                        {
                                            paymentFrequencyBadges.map(({id, value, ...rest }) =>
                                                <Badge
                                                    id={id}
                                                    key={id}
                                                    value={value}
                                                    style={{ width :'36%', height: '44px', fontSize: '14px', margin: '0' }}
                                                    selected={value === values.paymentFrequency}
                                                    onCLick={() => handlePaymentFrequencyBadgeClick(value as PaymentFrequency)}
                                                    { ...rest}
                                                />
                                            )
                                        }
                                        {
                                            showPaymentBadgeHelpMessages(values) && <span className={styles.helpMessage}>{errors.Amount || errors.paymentFrequency}</span>
                                        }
                                    </div>
                                </div>
                                <div className={styles.btnWrapper}>
                                    <Button
                                        type='submit'
                                        styleType='primary'
                                        disabled={isSubmitting || !isValid || !dirty}
                                        className={styles.submitBtn}
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
            }
        </>
    );
};

export default InitPaymentForm;

