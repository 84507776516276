import axios from "axios";

const { REACT_APP_SPREADSHEET_SERVICE: spreadsheetBaseUrl } = process.env

export const writeToSpreadsheet = async (spreadsheetId: string, values: any[]) => {
    try {
        await axios.post(`${spreadsheetBaseUrl}/writeToSpreadsheet`,{
            values: [values],
            spreadsheetId,
        }, {
            headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })
    } catch (e) {}
}
