import styles from './styles.module.css'

const CustomAmountInput = ({ value, onChange }: { value: string, onChange: (value: string) => void }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <input
            type="text"
            placeholder='CUSTOM AMOUNT'
            value={value}
            onChange={handleChange}
            style={value ? { backgroundColor: '#9A5AED', color: '#FFFFFF' } : {}}
            className={styles.customAmountInput}
        />
    );
};

export { CustomAmountInput }
