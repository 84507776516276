import {createBrowserRouter, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import AmeriaError from 'src/modules/payment/pages/ameriaError/ameriaError';
import Layout from 'src/components/Layout';
import ErrorPage from 'src/modules/shared/pages/errorPage/errorPage'
import InitPayment from "src/modules/payment/pages/payment/payment";
import AmeriaBack from "../modules/payment/pages/ameriaBack/ameriaBack";
import {parseLocationSearch} from "../helpers/locationSearch";

const CheckPaymentSuccess = () => {
    const location = useLocation();
    const queryParams =  parseLocationSearch(location.search);
    const navigate = useNavigate();

    useEffect(() => {
        if (!queryParams.paymentID) {
            navigate('/error', { state: { message: `Payment not found` } });
        }
    },[location.search])

    return <AmeriaBack />;
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/error",
                element: <ErrorPage/>,
            },
            {
                path: "/",
                element: <InitPayment />,
            },
            {
                path: "ameria-error",
                element: <AmeriaError/>,
            },
            {
                path: "ameria-back",
                element: <CheckPaymentSuccess />,
            },
        ]
    },
]);

export default router;
