import * as Yup from 'yup';

import { errorsMessages } from '../../shared/constants/errorMessages';
import { regex } from '../../shared/constants/regex';
import {PaymentFrequency} from "../types";
import {CURRENCY_TYPES} from "../../../services/payment/payment.types";

export const initPaymentInitialValues = {
    name: '',
    phone: '',
    email: '',
    Description: 'Statutory purposes',
    Amount: '',
    Currency: CURRENCY_TYPES.USD,
}

export const initPaymentSchema = () => {
    return Yup.object().shape({
        name: Yup.string()
            .required(errorsMessages.required),
        email: Yup.string()
            .required(errorsMessages.required)
            .matches(regex.emailRegEx, errorsMessages.email),
        phone: Yup.string()
            .required(errorsMessages.required)
            .matches(/^\d+$/, errorsMessages.phone),
        Amount: Yup.number().min(1)
            .required(errorsMessages.amount),
        Description: Yup.string().default('Statutory purposes'),
        paymentFrequency:Yup.string().required(errorsMessages.paymentFrequency).oneOf(Object.values(PaymentFrequency)),
    })
};

