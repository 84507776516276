import {CURRENCY_TYPES} from "../../services/payment/payment.types";

export interface IPaymentForm {
    name: string;
    phone: string;
    email: string;
    Description: string;
    Amount: string;
    Currency: CURRENCY_TYPES;
    paymentFrequency?: PaymentFrequency;
}

export enum PaymentFrequency {
    ONE_TIME = 'ONE_TIME',
    MONTHLY = 'MONTHLY'
}
